import { AnimatePresence, motion } from 'framer-motion';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import TailwindIndicator from '../tailwindIndicator';
import Footer from './footer/footer';
import Header from './header';
import ScrollButton from './scrollButton';

const duration = 0.5;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

interface OwnProps {
  children: React.ReactNode;
}

const Layout = ({ children, location }: PageProps<OwnProps>) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <AnimatePresence>
        <motion.main key={location.pathname} variants={variants} initial="initial" animate="enter" exit="exit">
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
      <ScrollButton />
      <TailwindIndicator />
    </>
  );
};

export default Layout;
