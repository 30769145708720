import * as React from 'react';
import { IIcon } from './iIcon';

export const Ski = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 189 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M151.666 152.17C151.666 152.17 143.166 154.67 133.166 152.17M120.666 155.67C128.666 159.67 133.166 159.67 141.166 159.67M72.666 163.67C79.166 161.67 83.666 160.17 89.666 156.17M106.666 152.17C100.166 152.17 90.166 149.67 90.166 149.67C77.666 162.17 60.666 161.17 50.166 156.17L37.666 162.67M106.666 152.17C105.643 145.632 91.9103 66.6705 90.666 60.1703C89.4218 53.6702 83.166 32.171 90.166 26.1707C92.9828 23.7562 99.666 22.6703 101.666 29.6698C103.268 35.2762 101.866 41.6703 102.166 54.6703C102.466 67.6703 116.666 148.797 116.666 148.797M106.666 152.17C111.166 152.17 116.666 148.797 116.666 148.797M116.666 148.797L123.666 149.155M123.666 149.155C123.666 149.155 123.723 108.671 124.666 95.1703C126.133 74.1748 126.666 60.1703 130.166 40.1707C133.666 20.171 151.166 25.6711 145.666 40.1707C140.166 54.6703 138.166 81.6707 136.666 99.1707C135.166 116.671 133.98 134.625 133.166 152.17M123.666 149.155L133.166 152.17"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.166 75.1047L61.666 74.6047C61.666 74.6047 59.166 72.1047 61.666 70.1047C63.166 64.6047 61.166 60.1047 62.666 57.1047C63.7212 54.9944 68.166 55.6047 68.666 59.1047C69.0903 62.0745 66.166 65.6047 66.166 71.1047C67.0262 72.8251 66.2421 73.8878 65.4167 74.4813C64.8028 74.9228 64.166 75.1047 64.166 75.1047Z"
      fill="#9593A9"
    />
    <path
      d="M49.166 155.905L61.666 74.6047M61.666 74.6047L64.166 75.1047M61.666 74.6047C61.666 74.6047 59.166 72.1047 61.666 70.1047M64.166 75.1047C64.166 75.1047 64.8028 74.9228 65.4167 74.4813M64.166 75.1047L52.166 156.17M66.166 71.1047C66.166 65.6047 69.0903 62.0745 68.666 59.1047C68.166 55.6047 63.7212 54.9944 62.666 57.1047C61.166 60.1047 63.166 64.6047 61.666 70.1047M66.166 71.1047C64.4087 70.7142 63.4234 70.4952 61.666 70.1047M66.166 71.1047C67.0262 72.8251 66.2421 73.8878 65.4167 74.4813M66.166 71.1047L61.666 70.1047M65.4167 74.4813C65.4167 74.4813 66.1508 78.8318 67.666 81.1047C68.666 82.6047 70.1862 84.2056 70.666 86.6047C71.166 89.1047 69.166 91.0047 67.166 90.6047C64.5922 90.0899 64.666 80.6699 64.666 75.6699"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.3725 64.8336L49.9214 64.335C49.9214 64.335 52.4703 61.842 49.9214 59.8477C48.392 54.3632 50.4312 49.876 48.9018 46.8845C47.826 44.7801 43.2942 45.3887 42.7844 48.8788C42.3518 51.8402 45.3333 55.3604 45.3333 60.8449C44.4563 62.5604 45.2557 63.6201 46.0973 64.2119C46.7232 64.6521 47.3725 64.8336 47.3725 64.8336Z"
      fill="#9593A9"
    />
    <path
      d="M64.666 159.17L58.0946 116.326M49.9214 64.335L47.3725 64.8336M49.9214 64.335C49.9214 64.335 52.4703 61.842 49.9214 59.8477M49.9214 64.335L56.6552 107.17M47.3725 64.8336C47.3725 64.8336 46.7232 64.6521 46.0973 64.2119M47.3725 64.8336L55.166 116.326M61.666 159.17L56.666 126.237M45.3333 60.8449C45.3333 55.3604 42.3518 51.8402 42.7844 48.8788C43.2942 45.3887 47.826 44.7801 48.9018 46.8845C50.4312 49.876 48.392 54.3632 49.9214 59.8477M45.3333 60.8449C47.1251 60.4554 48.1296 60.2371 49.9214 59.8477M45.3333 60.8449C44.4563 62.5604 45.2557 63.6201 46.0973 64.2119M45.3333 60.8449L49.9214 59.8477M46.0973 64.2119C46.0973 64.2119 45.3489 68.5501 43.804 70.8166C42.7844 72.3123 41.2345 73.9087 40.7452 76.301C40.2355 78.794 42.2746 80.6886 44.3137 80.2897C46.9379 79.7764 47.166 72.1558 47.166 67.1699"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
