import * as React from 'react';
import Link from '../../ui/link';
import Creator from './creator';

const Footer = () => {
  return (
    <footer className="h-[600px] space-y-6 bg-[#313131] pt-12 text-right text-lg text-secondary md:text-xl lg:text-2xl">
      <div className="flex flex-col justify-between h-full">
        <div className="flex items-center px-6 text-secondary md:px-9 lg:px-12">
          <Link
            className="text-xs text-white no-underline transition-colors duration-300 ease-in-out hover:text-[#d9aa5d] lg:text-sm"
            to="https://www.instagram.com/villahochvier.kleinwalsertal/"
          >
            <svg className="h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 16V8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3H16C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8V16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21H8C6.67392 21 5.40215 20.4732 4.46447 19.5355C3.52678 18.5979 3 17.3261 3 16Z"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M17.5 6.51002L17.51 6.49902"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
        <div className="px-6 md:px-9 lg:px-12">
          <div className="flex flex-col space-y-6">
            <Link className="" to="/villa-elfer/">
              Villa Elfer
            </Link>
            <Link className="" to="/villa-heuberg/">
              Villa Heuberg
            </Link>
            <Link className="" to="/villa-steinmandl/">
              Villa Steinmandl
            </Link>
            <Link className="" to="/villa-widderstein/">
              Villa Widderstein
            </Link>
            <Link className="" to="/kontakt/">
              Kontakt
            </Link>
            <Link className="" to="/gdpr/">
              Datenschutzerklärung
            </Link>
            <Link className="" to="/legal-notice/">
              Impressum
            </Link>
          </div>
        </div>
        <p className="px-6 text-sm font-light text-center md:px-9 lg:px-12">
          &copy; {new Date().getFullYear()} Alle Rechte vorbehalten, Villa hoch vier
        </p>
        <Creator />
      </div>
    </footer>
  );
};

export default Footer;
