import React, { useRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';
import { useLocation } from '@reach/router';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

function Link({
  children,
  to,
  activeClassName,
  onClick,
  ...other
}: Omit<Omit<Omit<GatsbyLinkProps<{}>, 'ref'>, 'activeStyle'>, 'partiallyActive'>) {
  const { pathname } = useLocation();
  const linkRef = useRef<any>(null);
  const internal = /^\/(?!\/)/.test(to);

  if (activeClassName) {
    if (pathname === to) {
      linkRef.current && linkRef.current.classList.add(activeClassName);
    } else {
      linkRef.current && linkRef.current.classList.remove(activeClassName);
    }
  }

  if (internal) {
    return (
      <GatsbyLink
        ref={linkRef}
        to={to}
        onClick={e => {
          onClick && onClick(e);
        }}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} target="_blank" rel="noreferrer" {...other}>
      {children}
    </a>
  );
}

export default Link;
