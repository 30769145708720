import * as React from 'react';
import Link from '../../ui/link';

const Creator = () => {
  return (
    <div className="bg-[#414141] p-2">
      <Link
        className="text-xs text-white no-underline transition-colors duration-300 ease-in-out hover:text-[#d9aa5d] lg:text-sm"
        to="https://moments-fotografie.com/"
      >
        <span className="hidden sm:inline">Website Konzeption, Gestaltung & Programmierung:&nbsp;</span>
        <span className="sm:hidden">Website Programmierung:&nbsp;</span>
        moments FOTOGRAFIE
      </Link>
    </div>
  );
};

export default Creator;
