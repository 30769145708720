import * as React from 'react';
import { IIcon } from './iIcon';

export const Bus = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 188 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.005 121.646C75.005 121.151 75.4062 120.75 75.9011 120.75H111.945C112.44 120.75 112.841 121.151 112.841 121.646V140.217C112.841 140.712 112.44 141.113 111.945 141.113H75.9011C75.4062 141.113 75.005 140.712 75.005 140.217V121.646Z"
      fill="#5E728A"
    />
    <path
      d="M54.7142 112.285C59.7222 112.285 63.7817 115.811 63.7817 120.159C63.7817 124.508 59.7222 128.033 54.7142 128.033M54.7142 112.285H39.6113M54.7142 112.285H55.1915M54.7142 128.033H55.1915M54.7142 128.033H41.4034M133.251 112.285C128.243 112.285 124.183 115.811 124.183 120.159C124.183 124.508 128.243 128.033 133.251 128.033M133.251 112.285H148.354M133.251 112.285H132.773M133.251 128.033H132.773M133.251 128.033H146.562M35.9993 59.9971C35.9993 59.9971 25.5032 56.9934 24.4993 59.9971C23.4955 63.0008 23.5364 81.6186 24.4993 84.4971C25.4623 87.3755 35.9993 84.4971 35.9993 84.4971M151.977 59.9971C151.977 59.9971 162.48 56.9934 163.477 59.9971C164.473 63.0008 164.514 81.6948 163.477 84.5732C162.439 87.4517 151.977 84.5732 151.977 84.5732M44.9873 148.441H141.763C152.423 119.191 156.099 42.2582 141.763 30.1613C127.427 18.0644 57.9797 18.5125 44.9873 30.1613C31.9949 41.81 37.0151 119.191 44.9873 148.441ZM74.109 27.9208H115.328C121.601 27.9209 121.601 35.0896 115.328 35.0896H74.109C67.8365 35.0897 67.8365 27.9208 74.109 27.9208ZM49.4676 48.9786C57.5321 39.57 129.218 39.57 137.283 48.9786C145.347 58.3872 139.971 97.8139 139.971 97.8139H46.7794C46.7794 97.8139 41.403 58.3872 49.4676 48.9786ZM75.9011 141.113H111.945C112.44 141.113 112.841 140.712 112.841 140.217V121.646C112.841 121.151 112.44 120.75 111.945 120.75H75.9011C75.4062 120.75 75.005 121.151 75.005 121.646V140.217C75.005 140.712 75.4062 141.113 75.9011 141.113Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.6758 147.545V161.352C47.6758 167.088 51.0858 166.739 55.2923 166.739C59.4988 166.739 62.9088 167.088 62.9088 161.352V147.545"
      stroke="#333333"
      strokeWidth="3"
    />
    <path
      d="M123.842 147.545V161.352C123.842 167.088 127.252 166.739 131.458 166.739C135.665 166.739 139.075 167.088 139.075 161.352V147.545"
      stroke="#333333"
      strokeWidth="3"
    />
  </svg>
);
