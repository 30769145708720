import * as React from 'react';
import { IIcon } from './iIcon';

export const Sign = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 189 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.0597 163.693V36.2543C67.0602 20.3239 104.231 20.3242 91.1968 36.2543V163.693"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.956 40.1152L97.6158 44.4597V66.1822L157.956 70.5267L175.334 55.0796L157.956 40.1152Z"
      fill="#E36D3B"
    />
    <path
      d="M157.956 40.1152L97.6158 44.4597V66.1822L157.956 70.5267L175.334 55.0796L157.956 40.1152Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.543 83.0774L97.6158 86.4565V101.904L141.543 105.283L154.094 94.18L141.543 83.0774Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.6396 84.5254V67.6302L26.3675 63.7684L13.334 75.8364L26.3675 87.9045L74.6396 84.5254Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
