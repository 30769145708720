import * as React from 'react';
import { IIcon } from './iIcon';

export const Sauna = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.0003 63.0547C86.8266 53.2444 74.0003 40.5547 74.0003 40.5547C74.0003 40.5547 61.174 53.2444 61.0003 63.0547C60.7172 79.0462 87.2834 79.0462 87.0003 63.0547Z"
      fill="#5E728A"
    />
    <path
      d="M32.0451 99.5547L31.5 103.055C31.5007 109.555 37.9991 116.055 43.5 116.055H103.5C110.001 116.055 116.5 109.555 116.5 103.055L107 42.0547C105.5 35.5547 97.5 31.0547 93.5 31.0547H54.5C50.5 31.0547 42.5 36.0547 41 42.0547L32.0451 99.5547ZM32.0451 99.5547C32.0451 99.5547 35 90.5547 43.5 90.0547H97C108.5 92.0547 108.5 106.555 97 108.555H47.5C41 107.555 41 99.5547 47.5 98.5547H70H92.5M74.0003 40.5547C74.0003 40.5547 86.8266 53.2444 87.0003 63.0547C87.2834 79.0462 60.7172 79.0462 61.0003 63.0547C61.174 53.2444 74.0003 40.5547 74.0003 40.5547Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
