import * as React from 'react';
import { IIcon } from './iIcon';

export const Wlan = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70898 58.8874C6.70898 58.8874 26.7146 19.2207 74.0006 19.2207C121.287 19.2207 141.292 58.8874 141.292 58.8874"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M26.541 82.5117C26.541 82.5117 40.6502 54.8867 73.9993 54.8867C107.348 54.8867 121.458 82.5117 121.458 82.5117"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M47.791 103.22C47.791 103.22 55.5827 85.5117 73.9994 85.5117C92.416 85.5117 100.208 103.22 100.208 103.22"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <circle cx="73.9994" cy="116.554" r="9.83333" fill="#E36D3B" stroke="#333333" strokeWidth="3" />
  </svg>
);
