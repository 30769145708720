exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-konzept-tsx": () => import("./../../../src/pages/konzept.tsx" /* webpackChunkName: "component---src-pages-konzept-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-villa-elfer-tsx": () => import("./../../../src/pages/villa-elfer.tsx" /* webpackChunkName: "component---src-pages-villa-elfer-tsx" */),
  "component---src-pages-villa-heuberg-tsx": () => import("./../../../src/pages/villa-heuberg.tsx" /* webpackChunkName: "component---src-pages-villa-heuberg-tsx" */),
  "component---src-pages-villa-steinmandl-tsx": () => import("./../../../src/pages/villa-steinmandl.tsx" /* webpackChunkName: "component---src-pages-villa-steinmandl-tsx" */),
  "component---src-pages-villa-widderstein-tsx": () => import("./../../../src/pages/villa-widderstein.tsx" /* webpackChunkName: "component---src-pages-villa-widderstein-tsx" */)
}

