import { Logo } from '@/components/icons/logo';
import { Menu } from '@headlessui/react';
import { navigate } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';
import Hamburger from './hamburger';
import NavButton from './navButton';
import NavDropdown from './navDropdown';
import NavLink from './navLink';

interface OwnProps {
  siteTitle: string;
  isFullHeight?: boolean;
}

const cottageList = [
  {
    label: 'Villa Elfer',
    to: '/villa-elfer/',
    color: '#5e728a',
  },
  {
    label: 'Villa Heuberg',
    to: '/villa-heuberg/',
    color: '#e36d3b',
  },
  {
    label: 'Villa Steinmandl',
    to: '/villa-steinmandl/',
    color: '#9593a9',
  },
  {
    label: 'Villa Widderstein',
    to: '/villa-widderstein/',
    color: '#638c6c',
  },
];

function Header({ siteTitle, isFullHeight = true }: OwnProps) {
  const [isOpen, setIsOpen] = useState(false);

  if (isFullHeight) {
    if (typeof document !== 'undefined') {
      if (isOpen) {
        document.body.classList.add('blockSiteScrolling');
      } else {
        document.body.classList.remove('blockSiteScrolling');
      }
    }
  }

  return (
    <header className={`${isFullHeight && isOpen && 'h-screen lg:h-auto'} border-b`}>
      <nav className="flex flex-wrap h-full lg:mx-auto">
        <NavLink label="" to="/" icon={<Logo className="mr-3 text-black" />} onClick={() => setIsOpen(false)} />
        <div className="flex-grow" />
        <NavButton
          className="lg:hidden"
          icon={<Hamburger open={isOpen} title="Menu" color="#000" />}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <div
          className={`${
            isOpen ? `flex` : `hidden`
          } my-6 h-full w-full flex-col overflow-auto lg:my-0 lg:flex lg:w-auto lg:flex-row lg:items-stretch lg:overflow-visible`}
        >
          <NavLink
            className="px-4 py-2 text-black/75 lg:flex-col lg:justify-center lg:text-center lg:text-[.95rem] lg:uppercase"
            to={`/konzept/`}
            onClick={() => setIsOpen(false)}
            label="Konzept"
            icon={
              <svg
                className="w-5 h-5 mr-3 lg:hidden text-clack"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                fill="none"
                viewBox="0 0 34 34"
              >
                <path
                  stroke="currentColor"
                  strokeWidth="2"
                  d="M11.947 23.28c0-4.02 5.301-8.386 5.301-8.386s5.085 4.585 5.085 8.386c0 3.801-2.247 5.306-5.085 5.367-2.897.062-5.3-1.347-5.3-5.367z"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 22.333C17 24.938 17 33 23.175 33h7.72V17H33v-3.79L17 1 1 13.21V17h2.105v16h12.772M30.895 8.158V1.842H24.86v1.965"
                ></path>
              </svg>
            }
            ripple={true}
          />
          <div className="border-b lg:hidden" />
          <NavDropdown label="Ferienhäuser">
            {cottageList.map(item => (
              <Menu.Item key={item.to}>
                {({ active }) => (
                  <button
                    className={`relative flex items-center gap-2 px-4 py-2 btn-hover w-full whitespace-nowrap text-[.95rem] text-black/75${
                      active ? ' bg-black/5' : ''
                    }`}
                    onClick={async () => {
                      await navigate(item.to);
                    }}
                  >
                    <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="24" height="24" fill={item.color} />
                    </svg>
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </NavDropdown>
          {cottageList.map(item => (
            <NavLink
              key={item.to}
              className="lg:hidden px-4 py-2"
              to={item.to}
              onClick={() => setIsOpen(false)}
              label={item.label}
              icon={
                <svg
                  className="mr-3"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="4" width="12" height="12" fill={item.color} />
                </svg>
              }
              ripple={false}
            />
          ))}
          <div className="border-b lg:hidden" />
          <NavLink
            className="px-4 py-2 text-black/75 lg:flex-col lg:justify-center lg:text-center lg:text-[.95rem] lg:uppercase"
            to={`/kontakt/`}
            onClick={() => setIsOpen(false)}
            label="Kontakt & Lage"
            icon={
              <svg
                className="w-5 h-5 mr-3 lg:hidden text-clack"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="none"
                viewBox="0 0 32 32"
              >
                <circle cx="10.5" cy="12.5" r="2.5" fill="currentColor"></circle>
                <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M19 18L27 18"></path>
                <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M19 13L27 13"></path>
                <path
                  fill="currentColor"
                  d="M6 17.8c0-.477.178-.935.494-1.273A1.636 1.636 0 017.688 16h5.625c.447 0 .876.19 1.193.527.316.338.494.796.494 1.273 0 0 0 4.2-4.5 4.2S6 17.8 6 17.8z"
                ></path>
                <rect
                  width="30"
                  height="26"
                  x="1"
                  y="3"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  rx="2"
                ></rect>
              </svg>
            }
            ripple={true}
          />
        </div>
      </nav>
    </header>
  );
}

export default Header;
