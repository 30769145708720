import * as React from 'react';
import { IIcon } from './iIcon';

export const GreenHouse = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58 98.8738C58 84.5546 76.8854 69 76.8854 69C76.8854 69 95 85.3323 95 98.8738C95 112.415 86.9946 117.775 76.8854 117.993C66.5634 118.215 58 113.193 58 98.8738Z"
      fill="#638C6C"
      stroke="black"
      strokeWidth="3"
    />
    <path
      d="M76 95.5C76 104.779 76 133.5 98 133.5H125.5V76.5H133V63L76 19.5L19 63V76.5H26.5V133.5H72M125.5 45V22.5H104V29.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
