import * as React from 'react';
import { IIcon } from './iIcon';

export const Car = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M107.127 106.391V119.324C107.127 124.697 110.932 129.053 115.625 129.053C120.319 129.053 124.124 124.697 124.124 119.324V106.391"
      stroke="#333333"
      strokeWidth="3"
    />
    <path
      d="M33.8164 77.718C39.4052 77.718 43.9358 81.6523 43.9358 86.5055C43.9358 91.3587 39.4052 95.293 33.8164 95.293M34.349 77.718L18.9609 77.718M34.349 95.293L18.9609 95.293"
      stroke="#333333"
      strokeWidth="3"
    />
    <rect
      x="56.8887"
      y="79.5664"
      width="34.225"
      height="15.725"
      rx="1"
      fill="#9593A9"
      stroke="#333333"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M29.125 58.7096C29.125 58.7096 33.353 27.4007 34.2926 25.0642C35.2321 22.7277 37.7561 20.9074 40.8695 20.3912C54.9629 18.0546 74.6936 18.0547 74.6936 18.0547"
      stroke="#333333"
      strokeWidth="3"
    />
    <path
      d="M119.324 58.2423C119.324 58.2423 115.14 27.2932 114.21 24.9836C113.28 22.674 110.782 20.8746 107.701 20.3643C93.7527 18.0546 74.2253 18.0547 74.2253 18.0547"
      stroke="#333333"
      strokeWidth="3"
    />
    <path
      d="M20.3049 107.52H127.695C128.692 107.52 129.5 106.736 129.5 105.768V77.9203L119.325 57.5703H29.1375L18.5 77.9203V105.768C18.5 106.736 19.3081 107.52 20.3049 107.52Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M24.0508 106.391V119.324C24.0508 124.697 27.8557 129.053 32.5492 129.053C37.2428 129.053 41.0477 124.697 41.0477 119.324V106.391"
      stroke="#333333"
      strokeWidth="3"
    />
    <path
      d="M114.184 76.7922C108.595 76.7922 104.064 80.7265 104.064 85.5797C104.064 90.4329 108.595 94.3672 114.184 94.3672M113.651 76.7922L129.039 76.7922M113.651 94.3672L129.039 94.3672"
      stroke="#333333"
      strokeWidth="3"
    />
    <path
      d="M104.987 56.443C104.987 49.8017 99.3959 44.418 92.4992 44.418C85.6026 44.418 80.0117 49.8017 80.0117 56.443"
      stroke="#333333"
      strokeWidth="3"
    />
  </svg>
);
