import Ripple from '@/components/lib/ripple';
import * as React from 'react';
import Link from '../../ui/link';

interface OwnProps {
  className?: string;
  label: string;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  activeClassName?: string;
  ripple?: boolean;
}

const NavLink = ({ className, onClick, label, icon, ripple = true, iconPosition = 'start', ...other }: OwnProps) => (
  <Ripple>
    {({ onRippleClick }) => (
      <Link
        className={`${
          className ? `${className} ` : ''
        }relative btn-hover flex items-center text-[.95rem] text-black/75`}
        title={label}
        {...other}
      >
        <div
          className={`absolute top-0 bottom-0 left-0 right-0${ripple ? ' ripple ripple-dark' : ''}`}
          onClick={e => {
            onRippleClick(e);
            onClick && onClick(e);
          }}
        />
        {iconPosition === 'start' ? (
          <>
            {icon}
            {label}
          </>
        ) : (
          <>
            {label}
            {icon}
          </>
        )}
      </Link>
    )}
  </Ripple>
);

export default NavLink;
