import * as React from 'react';
import { IIcon } from './iIcon';

export const Bed = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.9796 58.2004H42.0816C32.898 58.2004 32.898 74.0327 42.0816 74.0327H61.9796C71.6735 74.0327 71.6735 58.2004 61.9796 58.2004Z"
      fill="#638C6C"
    />
    <path
      d="M109.429 58.2004H89.5306C80.3469 58.2004 80.3469 74.0327 89.5306 74.0327H109.429C119.122 74.0327 119.122 58.2004 109.429 58.2004Z"
      fill="#638C6C"
    />
    <path
      d="M13 90.8866V120.509H24.7347V102.633H126.776V120.509H138V90.8866M13 90.8866C61.8155 90.8866 89.1845 90.8866 138 90.8866M13 90.8866C13 80.1614 17.0816 74.0327 26.7755 74.0327C26.7755 74.0327 36.1042 74.0327 42.0816 74.0327M138 90.8866C138 80.1614 133.918 74.0327 124.735 74.0327C124.735 74.0327 118.467 74.0327 109.429 74.0327M125.755 65.3504V30.1103H114.02V37.2605C99.2245 27.046 50.7551 27.046 37.4898 37.2605V30.1103H25.7551V65.3504M51.2653 42.8785C67.0816 38.2819 82.898 38.2819 99.2245 42.8785M42.0816 74.0327C49.8523 74.0327 54.209 74.0327 61.9796 74.0327M42.0816 74.0327C32.898 74.0327 32.898 58.2004 42.0816 58.2004H61.9796C71.6735 58.2004 71.6735 74.0327 61.9796 74.0327M42.0816 74.0327H61.9796M61.9796 74.0327C72.7389 74.0327 78.7713 74.0327 89.5306 74.0327M89.5306 74.0327C97.3012 74.0327 101.658 74.0327 109.429 74.0327M89.5306 74.0327C80.3469 74.0327 80.3469 58.2004 89.5306 58.2004H109.429C119.122 58.2004 119.122 74.0327 109.429 74.0327M89.5306 74.0327H109.429"
      stroke="#333333"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
