import Ripple from '@/components/lib/ripple';
import * as React from 'react';

interface OwnProps {
  className?: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
}

const NavButton = ({ className, onClick, label, icon, iconPosition = 'start' }: OwnProps) => (
  <Ripple>
    {({ onRippleClick }) => (
      <div
        className={`${
          className ? `${className} ` : ''
        }relative btn-hover flex items-center px-4 py-2 text-[.95rem] text-black/75`}
      >
        <button
          className="ripple ripple-dark absolute top-0 bottom-0 left-0 right-0"
          onClick={e => {
            onRippleClick(e);
            onClick && onClick(e);
          }}
        />
        {iconPosition === 'start' ? (
          <>
            {icon}
            {label}
          </>
        ) : (
          <>
            {label}
            {icon}
          </>
        )}
      </div>
    )}
  </Ripple>
);

export default NavButton;
