import * as React from 'react';
import { IIcon } from './iIcon';

export const CableCar = ({ className }: IIcon) => (
  <svg
    className={className}
    width="150"
    height="150"
    viewBox="0 0 188 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M80.6816 99.5V171H90.1816V99.5H80.6816Z" fill="#638C6C" />
    <path d="M96.6816 99.5V171H106.182V99.5H96.6816Z" fill="#638C6C" />
    <path d="M80.6816 99.5V171H90.1816V99.5H80.6816Z" stroke="#333333" strokeWidth="3" strokeLinejoin="round" />
    <path d="M96.6816 99.5V171H106.182V99.5H96.6816Z" stroke="#333333" strokeWidth="3" strokeLinejoin="round" />
    <path
      d="M56.9046 99.5C53.5742 108.497 51.6816 118.814 51.6816 129.783C51.6816 133.611 51.9121 137.359 52.3537 141H72.1816V99.5H56.9046Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M132.51 141C132.951 137.359 133.182 133.611 133.182 129.783C133.182 118.814 131.289 108.497 127.959 99.5H114.682V141H132.51Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M52.8916 83.5C45.2724 96.2663 40.6816 112.751 40.6816 130.749C40.6816 148.984 45.3942 165.665 53.1943 178.5H132.169C139.969 165.665 144.682 148.984 144.682 130.749C144.682 112.751 140.091 96.2663 132.472 83.5H52.8916Z"
      stroke="#333333"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M100.521 18.4203C98.5094 17.0112 95.5974 17.6685 94.3859 19.8053C93.2844 21.7479 93.8159 24.1024 95.645 25.3834C97.657 26.7925 100.569 26.1352 101.781 23.9984C102.882 22.0559 102.351 19.7014 100.521 18.4203Z"
      stroke="#333333"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M116.87 14.7305C114.858 13.3213 111.945 13.9788 110.734 16.1156C109.632 18.0581 110.164 20.4125 111.993 21.6935C114.005 23.1026 116.917 22.4452 118.129 20.3084C119.23 18.3658 118.699 16.0115 116.87 14.7305Z"
      stroke="#333333"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M133.046 11.0791C131.034 9.67 128.121 10.3273 126.91 12.4642C125.808 14.4067 126.34 16.761 128.169 18.042C130.181 19.4511 133.093 18.7938 134.305 16.657C135.406 14.7144 134.875 12.3602 133.046 11.0791Z"
      stroke="#333333"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M84.5191 22.0326C82.5071 20.6234 79.5951 21.2808 78.3835 23.4176C77.2821 25.3602 77.8135 27.7144 79.6426 28.9954C81.6546 30.4046 84.5666 29.7472 85.7782 27.6104C86.8796 25.6679 86.3482 23.3136 84.5191 22.0326Z"
      stroke="#333333"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M111.866 40.3789C111.866 40.3789 113.353 48.6148 111.866 53.5307C110.998 56.3983 110.023 57.8523 108.435 60.3925C103.568 68.1762 90.7084 75.8316 90.7084 75.8316M90.7084 75.8316H64.9766M90.7084 75.8316H120.443"
      stroke="#333333"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.6816 45.5259L147.319 23.7969"
      stroke="#333333"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
