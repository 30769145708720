import React from 'react';
import './src/styles/main.scss';
import Layout from './src/components/layout';
import RootElement from './src/components/rootElements';

export const wrapPageElement = ({ element, props }) => (
  <RootElement>
    <Layout {...props}>{element}</Layout>
  </RootElement>
);
