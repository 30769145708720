import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { TriangleDown, TriangleUp } from '../../icons';
import Ripple from '@/components/lib/ripple';

interface OwnProps {
  children?: React.ReactNode;
  label: string;
  className?: string;
}

const NavDropdown = ({ children, label, className }: OwnProps) => {
  return (
    <Menu
      as="div"
      className={`relative hidden lg:flex lg:flex-col lg:justify-center${className ? ' ' + className : ''}`}
    >
      {({ open }) => (
        <>
          <Ripple>
            {({ onRippleClick }) => (
              <Menu.Button className="btn-hover flex h-full items-center px-4 py-2 text-[.95rem] uppercase text-black/75">
                <div className="ripple ripple-dark absolute top-0 bottom-0 left-0 right-0" onClick={onRippleClick} />
                {label}
                {open ? <TriangleUp /> : <TriangleDown />}
              </Menu.Button>
            )}
          </Ripple>
          <Transition
            enter="transform transition duration-100 ease-in"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transform transition duration-75 ease-out"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={open}
          >
            <Menu.Items className="absolute right-0 mt-[1px] origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">{children}</div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default NavDropdown;
