import * as React from 'react';
import { IIcon } from './iIcon';

export const Logo = ({ className }: IIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="55"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 3138 1275"
  >
    <g fill="#494C4E" fillRule="nonzero">
      <path
        d="M624.376 5.794l-27.024 58.962h27.024V5.794zM565.45 140.426l-28.917 63.834h57.991l-29.074-63.834z"
        transform="scale(4.16667)"
      ></path>
      <path
        d="M620.482 261.384l-17.847-39.068h-74.382l-17.647 39.068h-21.415l68.234-148.345h16.218l68.241 148.345h-21.402zm-150.412 0h-19.643V131.689h-79.919v-18.65h99.562v148.345zm-51.299 0h-99.562V113.039h19.641v129.696h79.921v18.649zM305.126 66.144L252.397 3.785h54.569L285.207 37.67l22.344 26.424-2.425 2.05zm-24.784 195.24H260.7V113.039h19.642v148.345zm-106.633 0H157.41L91.295 113.039h21.53l53.037 118.807 53.031-118.807h20.937l-66.121 148.345zM3.175 150.008V3.785h70.43L3.175 150.008zM640.253.609v64.147h17.511v15.878h-17.511v32.405h-15.877V80.634l-46.729-.013V69.363h.129l30.056-65.578.728-1.588.727-1.588H0V306h752.658V.609H640.253z"
        transform="scale(4.16667)"
      ></path>
    </g>
  </svg>
);
